import React from 'react'
import "../AboutUs/AboutUs.css"
import List from '../List/List'
export default function AboutUs() {
  return (
    <section className='about-container'>
        <h3>about us</h3>
        <p className='par'>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </p>
        <div className='about-row'>
            <div className="about-col">
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </p>
                <div className="list-container">
                    <List icon="fa fa-shopping-bag list-icon" name="Eiusmod Tempor" description="Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi" />
                    <List icon="fa fa-photo list-icon" name="Magni Dolores" description="Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum" />
                    <List icon="fa fa-bar-chart list-icon" name="Dolor Sitema" description="Minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat tarad limino ata" />
                </div>
            </div>
            <div className="about-col">
                <img src="about-img.svg" alt="about" />
            </div>
        </div>
        <div className="about-row">
            <div className="about-col">
                <img src="about-extra-1.svg" alt="about" />
            </div>
            <div className="about-col">
                <h4>
                Voluptatem dignissimos provident quasi corporis voluptates sit assumenda.
                </h4>
                <p>
                Ipsum in aspernatur ut possimus sint. Quia omnis est occaecati possimus ea. Quas molestiae perspiciatis occaecati qui rerum. Deleniti quod porro sed quisquam saepe. Numquam mollitia recusandae non ad at et a.
                </p>
                <p>
                Ad vitae recusandae odit possimus. Quaerat cum ipsum corrupti. Odit qui asperiores ea corporis deserunt veritatis quidem expedita perferendis. Qui rerum eligendi ex doloribus quia sit. Porro rerum eum eum.
                </p>
            </div>
        </div>
        <div className="about-row">
            <div className="about-col">
                <h4>
                Neque saepe temporibus repellat ea ipsum et. Id vel et quia tempora facere reprehenderit.
                </h4>
                <p>
                Delectus alias ut incidunt delectus nam placeat in consequatur. Sed cupiditate quia ea quis. Voluptas nemo qui aut distinctio. Cumque fugit earum est quam officiis numquam. Ducimus corporis autem at blanditiis beatae incidunt sunt.
                </p>
                <p>
                Voluptas saepe natus quidem blanditiis. Non sunt impedit voluptas mollitia beatae. Qui esse molestias. Laudantium libero nisi vitae debitis. Dolorem cupiditate est perferendis iusto.
                </p>
                <p>
                Eum quia in. Magni quas ipsum a. Quis ex voluptatem inventore sint quia modi. Numquam est aut fuga mollitia exercitationem nam accusantium provident quia.
                </p>
            </div>
            <div className="about-col">
                <img src="about-extra-2.svg" alt="about" />
            </div>
        </div>
    </section>
  )
}
