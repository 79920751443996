import React from 'react'
import "../Intro/Intro.css"
import Button from '../Button/Button'
export default function Intro(props) {
  return (
    <section id='intro'>
        <div className="intro-info">
        <h2>
            We provide <span>solutions</span> <br /> for your business!
        </h2>
        <div>
            <Button name="Get Started" btnCalss="button" />
            <Button name="Our services" btnCalss="button-active" />
        </div>
        </div>
        <div className="intro-bg">
            <img src="intro-img.svg" alt="intro" />
        </div>
    </section>
  )
}
