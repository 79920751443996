import React from 'react'
import "../Slider/Slider.css"
export default function Slide(props) {
  return (
    <div className='slide-container'>
        <div className="profile">
            <img src={props.img} alt="profile" />
        </div>
        <div className="slide-content">
            <h4>{props.title}</h4>
            <span>{props.job}</span>
            <p>{props.comment}</p>
        </div>
    </div>
  )
}
