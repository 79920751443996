import React from 'react'
import "../Services/Services.css"
import Card from '../Card/Card'
import { PiBookBookmarkThin } from "react-icons/pi";
import { GrAnalytics } from "react-icons/gr";
import { IoMdPaper } from "react-icons/io";
import { IoSpeedometerOutline } from "react-icons/io5";
import { TfiWorld } from "react-icons/tfi";
import { LuClock8 } from "react-icons/lu";


export default function Services() {
  return (
    <section className='services-container'>
        <h3>services</h3>
        <p className='par'>
        Laudem latine persequeris id sed, ex fabulas delectus quo. No vel partiendo abhorreant vituperatoribus.
        </p>
        <div className="service-content">
        <Card icon={<GrAnalytics  color="#ff689b" className='icon'/>}  title="Lorem Ipsum" desc="Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident" />
        <Card icon={<PiBookBookmarkThin   color="#e9bf06" className='icon' />} title="Lorem Ipsum" desc="Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident" />
        <Card icon={<IoMdPaper   color="#3fcdc7" className='icon' />} title="Lorem Ipsum" desc="Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident" />
        <Card icon={<IoSpeedometerOutline  color="#41cf2e" className='icon' /> } title="Lorem Ipsum" desc="Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident" />
        <Card icon={<TfiWorld  color="#d6ff22" className='icon' />} title="Lorem Ipsum" desc="Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident" />
        <Card icon={<LuClock8  color="#4680ff" className='icon' />} title="Lorem Ipsum" desc="Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident" />
            
        </div>
    </section>
  )
}
