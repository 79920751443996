import React from 'react'
import "../ChoseUs/ChoseUs.css"
import ChoseCard from '../Card/ChoseCard'
import { FaRegObjectGroup } from "react-icons/fa";
import { LiaLanguageSolid } from "react-icons/lia";
import { IoDiamondOutline } from "react-icons/io5";
import Counter from '../Counter/Counter';


export default function ChoseUs() {
  return (
    <section className='chose-container'>
        <h3>Why choose us?</h3>
        <p className='par'>
        Laudem latine persequeris id sed, ex fabulas delectus quo. No vel partiendo abhorreant vituperatoribus.
        </p>
        <div className="chose-row">
            <ChoseCard icon={<IoDiamondOutline />} title="Corporis dolorem" desc="Deleniti optio et nisi dolorem debitis. Aliquam nobis est temporibus sunt ab inventore officiis aut voluptatibus."/>
            <ChoseCard icon={<LiaLanguageSolid />} title="Voluptates dolores" desc="Voluptates nihil et quis omnis et eaque omnis sint aut. Ducimus dolorum aspernatur. aspenrin"/>
            <ChoseCard icon={<FaRegObjectGroup />} title="Eum ut aspernatur" desc="Autem quod nesciunt eos ea aut amet laboriosam ab. Eos quis porro in non nemo ex."/>
        </div>
        <div className="counter-row">
            <Counter count="274" name="clients"/>
            <Counter count="421" name="projects"/>
            <Counter count="1,384" name="hours of support"/>
            <Counter count="18" name="hard worker"/>
        </div>
    </section>
  )
}
