import React from 'react'
import "../List/List.css"
export default function List(props) {
  return (
    <div class="list">
      <div className="icon-container">
        <i className={props.icon}></i>
      </div>  
      <div class="list-content">
        <a href="#" className='list-icon'>{props.name}</a>
        <p class="description">{props.description}</p>
      </div>
    </div>
  )
}
