import React from 'react'
import "../Header/Header.css"
import { RxHamburgerMenu } from "react-icons/rx";

export default function Header() {
  // height become reduce when page scrolled
window.addEventListener("scroll",()=>{
    const scroll = window.scrollY;
    const scrollable = document.documentElement.scrollHeight - window.innerHeight;
    const scrolled = (scroll / scrollable) * 100;
    const fixScrolled = scrolled.toFixed(1);
    if (fixScrolled >= 2.3) {
      document.getElementById("progress").style.height = "60px"
      document.getElementById("header").style.top = "60px"
    }else{
      document.getElementById("progress").style.height = "80px"
      document.getElementById("header").style.top = "80px"
    }
})
// navigation or burger Menu
function handleMenu() {
  var x = document.getElementById("header");
  if (x.style.display === "flex") {
    x.style.display = "none";
  } else {
    x.style.display = "flex";
  }
}
  return (
    <header id='progress'>
        <div className="logo">
            <img src="techLand.png" alt="logo" />
        </div>
        <div className='header-links' id='header'>
            <a href="www" className='header-link'>home</a>
            <a href="www" className='header-link'>about us</a>
            <a href="www" className='header-link'>services</a>
            <a href="www" className='header-link'>portfolio</a>
            <a href="www" className='header-link'>team</a>
            <a href="www" className='header-link'>contact us</a>
        </div>
        <a href="#" className='menu' onClick={handleMenu}><RxHamburgerMenu /></a>
    </header>
  )
}
