import React from 'react'
import "../Counter/Counter.css"
export default function Counter(props) {
  return (
    <div className='counter'>
        <span>{props.count}</span>
        <p>{props.name}</p>
    </div>
  )
}
