import React from 'react'
import "../Team/Team.css"
import { FaFacebookF } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { BsTwitterX } from "react-icons/bs";
import { FaLinkedin } from "react-icons/fa";

export default function Team() {
  return (
    <section className='team-container'>
        <h3>Team</h3>
        <p className='par'>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </p>
        <div className="members">
            <div className="member">
                <img src="team-1.jpg" alt="member" />
                <div className="member-content">
                    <h3>Sarah Johnson</h3>
                    <span>Product Manager</span>
                    <div className="member-social">
                        <a href="#">
                        <FaFacebookF className='social-icon'/>
                        </a>
                        <a href="#">
                        <AiFillInstagram className='social-icon'/>
                        </a>
                        <a href="#">
                        <BsTwitterX className='social-icon'/>
                        </a>
                        <a href="#">
                        <FaLinkedin className='social-icon'/>
                        </a>
                    </div>
                </div>
            </div>
            <div className="member">
                <img src="team-2.jpg" alt="member" />
                <div className="member-content">
                    <h3>Sarah Johnson</h3>
                    <span>Product Manager</span>
                    <div className="member-social">
                        <a href="#">
                        <FaFacebookF className='social-icon'/>
                        </a>
                        <a href="#">
                        <AiFillInstagram className='social-icon'/>
                        </a>
                        <a href="#">
                        <BsTwitterX className='social-icon'/>
                        </a>
                        <a href="#">
                        <FaLinkedin className='social-icon'/>
                        </a>
                    </div>
                </div>
            </div>
            <div className="member">
                <img src="team-3.jpg" alt="member" />
                <div className="member-content">
                    <h3>Sarah Johnson</h3>
                    <span>Product Manager</span>
                    <div className="member-social">
                        <a href="#">
                        <FaFacebookF className='social-icon'/>
                        </a>
                        <a href="#">
                        <AiFillInstagram className='social-icon'/>
                        </a>
                        <a href="#">
                        <BsTwitterX className='social-icon'/>
                        </a>
                        <a href="#">
                        <FaLinkedin className='social-icon'/>
                        </a>
                    </div>
                </div>
            </div>
            <div className="member">
                <img src="team-4.jpg" alt="member" />
                <div className="member-content">
                    <h3>Sarah Johnson</h3>
                    <span>Product Manager</span>
                    <div className="member-social">
                        <a href="#">
                        <FaFacebookF className='social-icon'/>
                        </a>
                        <a href="#">
                        <AiFillInstagram className='social-icon'/>
                        </a>
                        <a href="#">
                        <BsTwitterX className='social-icon'/>
                        </a>
                        <a href="#">
                        <FaLinkedin className='social-icon'/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}
