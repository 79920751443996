import React from 'react'
import "../Footer/Footer.css"
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { IoLogoYoutube } from "react-icons/io";
import { FaTelegramPlane } from "react-icons/fa";

export default function Footer() {
  return (
    <footer className='footer-container'>
        <div className="footer-contet">
            <div className="footer-col">
                <h1>techland</h1>
                <p>
                Cras fermentum odio eu feugiat lide par naso tierra. Justo eget nada terra videa magna derita valies darta donna mare fermentum iaculis eu non diam phasellus. Scelerisque felis imperdiet proin fermentum leo. Amet volutpat consequat mauris nunc congue.
                </p>
            </div>
            <div className="footer-col">
                <h3>USEFUL LINKS</h3>
                <div className="footer-links">
                    <a href="#" className="footer-link">Home</a>
                    <a href="#" className="footer-link">About us</a>
                    <a href="#" className="footer-link">Services</a>
                    <a href="#" className="footer-link">Terms of services</a>
                    <a href="#" className="footer-link">Privecy policy</a>
                </div>
            </div>
            <div className="footer-col">
                <h3>CONTACT US</h3>
                <span>A108 Adam Street</span>
                <span>New York, NY 535022</span>
                <span>United States</span>
                <span><strong>Phone</strong>: +1 5589 55488 55</span>
                <span><strong>Email</strong>: info@example.com</span>
                <div className="socials">
                    <a href=""><FaFacebookF /></a>
                    <a href=""><FaInstagram /></a>
                    <a href=""><FaXTwitter /></a>
                    <a href=""><IoLogoYoutube /></a>
                    <a href=""><FaTelegramPlane /></a>
                </div>
            </div>
            <div className="footer-col">
                <h3>OUR NEWSLETTER</h3>
                <p>Tamen quem nulla quae legam multos aute sint culpa legam noster magna veniam enim veniam illum dolore legam minim quorum culpa amet magna export quem marada parida nodela caramase seza.</p>
                <div className="subscribe">
                    <input type="email" />
                    <button>subscribe</button>
                </div>
            </div>
        </div>
        <div className="copy">
            &copy;Copyright All Reserved By TechLand 
        </div>
    </footer>
  )
}
