import React from 'react'
import "../Card/ChoseCard.css"

export default function ChoseCard(props) {
  return (
    <div className='choseCard'>
        <div className="choseCard-icon">
            {props.icon}
        </div>
        <div className="choseCard-body">
        <h5>{props.title}</h5>
        <p class="card-text">{props.desc}</p>
        <a href="#" class="readmore">Read more </a>
        </div>
    </div>
  )
}
