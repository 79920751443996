import React from 'react'
import Header from '../../Components/UI/Header/Header'
import Intro from '../../Components/UI/Intro/Intro'
import AboutUs from '../../Components/UI/AboutUs/AboutUs'
import Services from '../../Components/UI/Services/Services'
import ChoseUs from '../../Components/UI/ChoseUs/ChoseUs'
import Portfolio from '../../Components/UI/Portfolio/Portfolio'
import Slider from '../../Components/UI/Slider/Slider'
import Team from '../../Components/UI/Team/Team'
import ContactUs from '../../Components/UI/ContactUs/ContactUs'
import Footer from '../../Components/UI/Footer/Footer'
import Upper from '../../Components/UI/Button/Upper'

export default function Home() {
  return (
    <div>
        <Header />
        <Intro />
        <AboutUs />
        <Services />
        <ChoseUs />
        <Portfolio />
        <Slider />
        <Team />
        <ContactUs />
        <Footer />
        <Upper />
    </div>
  )
}
