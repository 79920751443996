import React from 'react'
import { FaAngleUp } from "react-icons/fa";
import "../Button/Button.css"
export default function Upper() {
  window.addEventListener("scroll",()=>{
    const scroll = window.scrollY;
    const scrollable = document.documentElement.scrollHeight - window.innerHeight;
    const scrolled = (scroll / scrollable) * 100;
    const fixScrolled = scrolled.toFixed(1);
    if (fixScrolled >= 20) {
      document.getElementById("upper").style.display = "flex"
    }else{
      document.getElementById("upper").style.display = "none"
    }
})
  return (
    <a href="#" className='upper' id='upper'><FaAngleUp /></a>
  )
}
