import React from 'react'
import "../ContactUs/ContactUs.css"
import { IoLocationOutline } from "react-icons/io5";
import { MdOutlineMail } from "react-icons/md";
import { IoCallOutline } from "react-icons/io5";
import Button from '../Button/Button'
import Map from './Map'
export default function ContactUs() {
  return (
    <section className='contact-container'>
        <h3>Contact us</h3>
        <div className="contact">
            <div className="maps-container">
                <Map />
            </div>
            <div className="contact-content">
                <div className="contact-header">
                    <div className="contact-col">
                        <IoLocationOutline className='contact-icon'/>
                        <p>64 Metera Street, Herat, Afghanistan</p>
                    </div>
                    <div className="contact-col">
                        <MdOutlineMail className='contact-icon'/>
                        <p>technlogyLand01@gmail.com</p>
                    </div>
                    <div className="contact-col">
                        <IoCallOutline className='contact-icon'/>
                        <p>+93 796 14 65 14</p>
                    </div>
                </div>
                <div className="contact-form">
                    <div className="contact-group">
                        <input type="text" placeholder='your name'/>
                        <input type="email" placeholder='your email'/>
                    </div>
                    <input type="text" placeholder='subjec' />
                    <textarea name="message" id="message" placeholder='message'></textarea>
                    <Button name="Send Message" btnCalss="submit"/>
                </div>
            </div>
        </div>
    </section>
  )
}
