import React from 'react'
import "../Gallery/Gallery.css"
export default function Gallery() {
  return (
    <div class="container">
  <div class="gallery-container w-3 h-3">
    <div class="gallery-item">
      <div class="image">
        <img src="app1.jpg" alt="nature" />
      </div>
      <div class="text">nature 1</div>
    </div>
  </div>

  <div class="gallery-container w-3 h-3">
    <div class="gallery-item">
      <div class="image">
        <img src="web3.jpg" alt="people" />
      </div>
      <div class="text">people 2</div>
    </div>
  </div>

  <div class="gallery-container h-2">
    <div class="gallery-item">
      <div class="image">
        <img src="app2.jpg" alt="sport" />
      </div>
      <div class="text">sport 3</div>
    </div>
  </div>

  <div class="gallery-container w-2">
    <div class="gallery-item">
      <div class="image">
        <img src="web2.jpg" alt="fitness" />
      </div>
      <div class="text">fitness 4</div>
    </div>
  </div>

  <div class="gallery-container w-4 h-1">
    <div class="gallery-item">
      <div class="image">
        <img src="card2.jpg" alt="food" />
      </div>
      <div class="text">food 5</div>
    </div>
  </div>

  <div class="gallery-container">
    <div class="gallery-item">
      <div class="image">
        <img src="app2.jpg" alt="travel" />
      </div>
      <div class="text">travel 6</div>
    </div>
  </div>

  <div class="gallery-container h-2">
    <div class="gallery-item">
      <div class="image">
        <img src="web1.jpg" alt="art" />
      </div>
      <div class="text">art 7</div>
    </div>
  </div>

  <div class="gallery-container">
    <div class="gallery-item">
      <div class="image">
        <img src="card3.jpg" alt="cars" />
      </div>
      <div class="text">cars 8</div>
    </div>
  </div>




</div>
  )
}
