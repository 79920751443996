import React from 'react'
import "../Card/Card.css"

export default function Card(props) {
  return (
    <div className='cards'>
        <div className="cardIcon-container">
            {
                props.icon
            }
        </div>
        <div className="card-content">
            <a href="#">{props.title}</a>
            <p>
            {props.desc}
            </p>
        </div>  
    </div>
  )
}
