import React from 'react';
import Carousel from 'react-elastic-carousel';
import "../Slider/Slider.css"
import Slide from './Slide';
export default function Slider(){
        const items = [
          {id: 1, img:"testimonial-1.jpg", title: 'sara wilsson', job:"designer",comment:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore, dicta. Commodi, dolorum dolores. Eum odio eius dignissimos, molestiae nobis tempora?"},
          {id: 2, img:"testimonial-2.jpg", title: 'sara wilsson', job:"designer",comment:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore, dicta. Commodi, dolorum dolores. Eum odio eius dignissimos, molestiae nobis tempora?"},
          {id: 3, img:"testimonial-3.jpg", title: 'sara wilsson', job:"designer",comment:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore, dicta. Commodi, dolorum dolores. Eum odio eius dignissimos, molestiae nobis tempora?"},
          {id: 4, img:"testimonial-4.jpg", title: 'sara wilsson', job:"designer",comment:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore, dicta. Commodi, dolorum dolores. Eum odio eius dignissimos, molestiae nobis tempora?"},
          {id: 5, img:"testimonial-5.jpg", title: 'sara wilsson', job:"designer",comment:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore, dicta. Commodi, dolorum dolores. Eum odio eius dignissimos, molestiae nobis tempora?"},
        ]
    return (
        <div className="slider-container">
            <h3>Testimonials</h3>
            <Carousel className='slider' showArrows={false} enableAutoPlay={true}
            renderPagination={({ pages, activePage, onClick }) => {
                return (
                  <div className="slide-pagination">
                    {pages.map(page => {
                      const isActivePage = activePage === page
                      return (
                        <div
                          key={page}
                          onClick={() => onClick(page)}
                          active={isActivePage}
                          className='slide-pagination--btn'
                          style={isActivePage ? {background:"#007bff"}:{background:"#ddd"}}
                        ></div>
                      )
                    })}
                  </div>
                )
              }}
            >
                {
                    items.map(item=><Slide key={item.id} img={item.img} title={item.title} job={item.job} comment={item.comment}  />)
                }
                {/* {items.map(item => <div key={item.id}>{item.title}</div>)} */}
            </Carousel>
        </div>
    )
}
