import React from 'react'
import "../Portfolio/Portfolio.css"
import Tab from '../Button/Tab'
import Gallery from '../Gallery/Gallery'
export default function Portfolio() {
  return (
    <section className='portfolio-container'>
        <h3>our portfolio</h3>
        <div className="tab-row">
            <Tab value="ALL"/>
            <Tab value="APP"/>
            <Tab value="CARD"/>
            <Tab value="WEB"/>
        </div>
        <Gallery />
    </section>
  )
}
